<template>
  <div class="bitcoinDeal">
    <div v-title data-title="比特派"></div>
    <div class="Navbar">
      <img @click="gotoback" :src="imgUrl + 'left-arrow2.png'" />
      <div class="title">
        <ul style=" display: flex;">
          <li :class="activeIndex == 1 ? 'active' : ''" @click="changIndex(1)">
            比特派购买USDT
          </li>
          <li :class="activeIndex == 2 ? 'active' : ''" @click="changIndex(2)">
            存款到贝宝
          </li>
          <li :class="activeIndex == 3 ? 'active' : ''" @click="changIndex(3)">
            取款/出售USDT
          </li>
        </ul>
      </div>
    </div>
    <div>

      <div class="one-box" v-if="activeIndex == 1">
        <div class="title2" style="margin-top:43px">
          <div class="title2-item"></div>
          <div class="title-cont">第一步 下载安装</div>
          <div class="title2-item"></div>
        </div>
        <p style="color:var(--li-colortext)">IOS可再APPLE STORE中搜索BITPIE下载此APP，安卓扫码二维码下载</p>
        <img :src="imgUrl + 'bitcoin_qrcode.png'" />

        <div class="title2">
          <div class="title2-item"></div>
          <div class="title-cont">第二步 注册购买</div>
          <div class="title2-item"></div>
        </div>

        <div class="lunbo-box">
          <van-swipe class="my-swipe" indicator-color="#1989fa" ref="swipe1">
            <van-swipe-item v-for="(item, index) in imagesUrl" :key="index">
              <p style="color:var(--li-colortext)">{{ item.title }}</p>
              <div style="display:flex;justify-content: center;align-items: center;">
                <img style="width:72%;height:100%" :src="item.img" />
              </div>
            </van-swipe-item>
          </van-swipe>

          <div class="zuo" @click="pervImg">
            <img :src="imgUrl + 'zuo.png'" />
          </div>
          <div class="you" @click="nextImg">
            <img :src="imgUrl + 'you.png'" />
          </div>
        </div>
      </div>

      <div class="one-box" v-if="activeIndex == 2">
        <div class="lunbo-box">
          <van-swipe class="my-swipe" indicator-color="#1989fa" ref="swipe2">
            <van-swipe-item v-for="(item, index) in imagesUrl2" :key="index">
              <p style="color:var(--li-colortext)">{{ item.title }}</p>
              <div style="display:flex;justify-content: center;align-items: center;">
                <img style="width:72%;height:100%" :src="item.img" />
              </div>
            </van-swipe-item>
          </van-swipe>

          <div class="zuo" @click="pervImg2">
            <img :src="imgUrl + 'zuo.png'" />
          </div>
          <div class="you" @click="nextImg2">
            <img :src="imgUrl + 'you.png'" />
          </div>
        </div>
      </div>

      <div class="one-box" v-if="activeIndex == 3">
        <div class="lunbo-box">
          <van-swipe class="my-swipe" indicator-color="#1989fa" ref="swipe3">
            <van-swipe-item v-for="(item, index) in imagesUrl3" :key="index">
              <p style="color:var(--li-colortext)">{{ item.title }}</p>
              <div style="display:flex;justify-content: center;align-items: center;">
                <img style="width:72%;height:100%" :src="item.img" />
              </div>
            </van-swipe-item>
          </van-swipe>

          <div class="zuo" @click="pervImg3">
            <img :src="imgUrl + 'zuo.png'" />
          </div>
          <div class="you" @click="nextImg3">
            <img :src="imgUrl + 'you.png'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import "vant/lib/swipe/style";

export default {
  data () {
    return {
      imgIndex: 0,
      activeIndex: 1,
      imagesUrl: [
        {
          img: this.imgUrl + 'bitcoin1.jpg',
          title: "1.打开bitpie APP，点击开始使用",
        },
        {
          img: this.imgUrl + 'bitcoin2.jpg',
          title: "2.点击下一步获取12个英文单词并记录",
        },
        {
          img: this.imgUrl + 'bitcoin3.jpg',
          title: "3.验证12个英文单词并进入下一步",
        },
        {
          img: this.imgUrl + 'bitcoin4.jpg',
          title: "4.设置安全码，成功后开启usdt货币",
        },
        {
          img: this.imgUrl + 'bitcoin5.jpg',
          title: "5.选择【我】选择【收款银行卡/支付宝】绑定支付方式",
        },
        {
          img: this.imgUrl + 'bitcoin6.jpg',
          title: "6.返回钱包首页，可在一键买卖中购买usdt",
        },
        {
          img: this.imgUrl + 'bitcoin7.jpg',
          title: "7.进入支付信息界面付款成功后，点击确认已付款",
        },
      ],
      imagesUrl2: [
        {
          img: this.imgUrl + 'bitcoinDpt1.jpg',
          title: '1.点击转账'
        },
        {
          img: this.imgUrl + 'bitcoinDpt2.jpg',
          title: '2.输入贝宝存款页面显示的收款地址及转帐信息或通过扫描存款页面提供的二维码后将USDT转入贝宝帐户'
        },
        {
          img: this.imgUrl + 'bitcoinDpt3.jpg',
          title: '3.点击【收款】'
        },
        {
          img: this.imgUrl + 'bitcoinDpt4.jpg',
          title: '4.在【USDT-OMNI收款地址】可点击地址字符右下方复制后绑定到贝宝游戏账号'
        },
      ],
      imagesUrl3: [
        {
          img: this.imgUrl + 'bitcoinDraw1.jpg',
          title: '1.点击一键买卖'
        },
        {
          img: this.imgUrl + 'bitcoinDraw2.jpg',
          title: '2.点击【出售】并填写交易信息后提交订单'
        },


      ],
    };
  },
  mounted () { },
  methods: {
    pervImg () {
      this.$refs.swipe1.prev();
    },
    nextImg () {
      this.$refs.swipe1.next();
    },

    pervImg2 () {
      this.$refs.swipe2.prev();
    },
    nextImg2 () {
      this.$refs.swipe2.next();
    },

    pervImg3 () {
      this.$refs.swipe3.prev();
    },
    nextImg3 () {
      this.$refs.swipe3.next();
    },
    changimgIndex (index) {
      if (index == this.imgIndex) return;
      this.imgIndex = index;
    },
    gotoback () {
      // uni.navigateBack({});
      this.$router.go(-1)
    },
    changIndex (index) {
      if (index == this.activeIndex) return;
      this.imgIndex = 0;
      this.activeIndex = index;
    },
  },
};
</script>
<style></style>
<style lang="less">
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.my-swipe {
  margin-top: 24px;
  padding-bottom: 50px;
}

.van-swipe__indicator {
  background-color: #999;
  width: 10px;
  height: 10px;
}

body {
  background-color: #f5f5f5;
}

ul,
li {
  list-style: none;
}

.bitcoinDeal {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    padding-left: 10px;

    >img {
      height: 20px;
      width: 20px;
    }

    .title {
      font-size: 13px;
      flex: 1;
      text-align: center;
      color: #000;

      ul {
        height: 43px;
      }

      li {
        height: 43px;
        line-height: 43px;
        margin: 0 10px;
      }

      .active {
        color: #5780e9;
        border-bottom: 2px solid #5780e9;
      }
    }
  }

  .one-box {
    margin: 0 20px;
    margin-top: 64px;

    .lunbo-box {
      position: relative;

      .zuo {
        position: absolute;
        top: calc(50% - 30px);
        left: 0;
        width: 25px;
        height: 25px;

        >img {
          width: 100%;
          height: 100%;
        }
      }

      .you {
        position: absolute;
        top: calc(50% - 30px);
        right: 0;
        width: 22px;
        height: 22px;

        >img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: #000;
        margin: 20px 40px;
      }
    }

    .title2 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #6386fe;

      .title2-item {
        width: 12px;
        height: 12px;
        border: 5px solid royalblue;
        background-color: #fff;
        border-radius: 50%;
        margin: 0 10px;
      }
    }

    >p {
      text-align: center;
      font-size: 14px;
      color: #000;
      margin: 10px 20px;
      font-weight: 400;
    }

    >img {
      padding: 15px 60px 30px 60px;
      width: 100%;
    }
  }
}
</style>
